<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--  <div class="d-flex align-items-center">
      <div
        class="
          btn btn-icon btn-active-light-primary
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        id="kt_activities_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/general/gen032.svg" />
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div
        class="
          btn btn-icon btn-active-light-primary
          position-relative
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <span class="svg-icon svg-icon-gray-500 svg-icon-2x"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
                fill="#000000"
              />
              <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5" />
            </g></svg
        ></span>
        <span
          class="
            bullet bullet-dot
            bg-danger
            h-6px
            w-6px
            position-absolute
            translate-middle
            animation-blink
          "
          style="top: 34%; left: 67%"
        >
        </span>
      </div>
      <Notification></Notification>
    </div> -->
    <div class="d-flex align-items-center" id="kt_header_user_menu_toggle">
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px me-4 ms-3"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <h3 class="badge bg-gray-500 username" style="margin-bottom: 0px">
          {{ name }}
        </h3>
      </div>
      <div class="menu-item">
        <a @click="signOut()" class="esci px-5">
          Esci <i class="bi bi-box-arrow-right fs-2 ps-2"></i>
        </a>
      </div>
      <!--  <MenuUser></MenuUser> -->
    </div>
    <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    ></div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

//import Notification from "@/components/components-fit/utility/Notification.vue";
import MenuUser from "@/components/components-fit/utility/MenuUser.vue";
import { useMsal } from "@/composition-api/useMsal";

import { useStore } from "vuex";

import { useCleanStore } from "@/composables/emptyStore";

export default defineComponent({
  name: "topbar",
  components: {
    //Notification,
    //MenuUser,
  },
  setup() {
    const store = useStore();

    const { instance } = useMsal();

    const signOut = async () => {
      const idTokenHint = localStorage.getItem("tokenAdb2c");
      localStorage.clear();
      useCleanStore();
      await instance.logoutRedirect({
        idTokenHint: idTokenHint,
        // onRedirectNavigate: (url) => {
        //   return false;
        // },
      });
    };
    if (localStorage.getItem("mustLogout")) {
      signOut();
    }
    return {
      name: computed(() => store.getters.userName),
      signOut,
    };
  },
});
</script>

<style></style>
